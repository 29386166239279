import React from 'react';
import { motion } from 'framer-motion';

const StatsKeywordsSwitch = ({ activeTab, setActiveTab }) => {
  return (
    <div className="flex justify-center mb-4">
      <div className="bg-secondary rounded-full p-1 flex">
        <motion.button
          className={`px-4 py-2 rounded-full ${activeTab === 'stats' ? 'bg-primary text-primary-foreground' : 'text-secondary-foreground'}`}
          onClick={() => setActiveTab('stats')}
          whileTap={{ scale: 0.95 }}
        >
          Stats
        </motion.button>
        <motion.button
          className={`px-4 py-2 rounded-full ${activeTab === 'keywords' ? 'bg-primary text-primary-foreground' : 'text-secondary-foreground'}`}
          onClick={() => setActiveTab('keywords')}
          whileTap={{ scale: 0.95 }}
        >
          Keywords
        </motion.button>
      </div>
    </div>
  );
};

export default StatsKeywordsSwitch;

