import React from 'react';
import { FaTimes } from 'react-icons/fa';

const SettingsPopup = ({ isOpen, onClose, settings, onUpdateSettings }) => {
  if (!isOpen) return null;

  const renderSettings = () => (
    <div className="space-y-2 max-h-80 overflow-y-auto">
      {Object.entries(settings.buttons).map(([key, value]) => {
        if (key === 'Share') return null; // Exclude Share button from settings
        return (
          <div key={key} className="flex items-center justify-between">
            <label htmlFor={key} className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                id={key}
                checked={value}
                onChange={() => onUpdateSettings('buttons', key, !value)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2 text-gray-700 dark:text-gray-300">{formatButtonLabel(key)}</span>
            </label>
          </div>
        );
      })}
    </div>
  );

  const formatButtonLabel = (key) => {
    return key
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase());
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-80 max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Button Settings</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>
        {renderSettings()}
      </div>
    </div>
  );
};

export default SettingsPopup;
