import React, { useMemo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import commonWords from './commonWords';
import { FaSort, FaSortAlphaDown, FaSortAlphaUp, FaSortNumericDown, FaSortNumericUp, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Tooltip from './Tooltip';

const KeywordsBox = ({ text, settings = {}, onUpdateSettings }) => {
  const [sortBy, setSortBy] = useState('frequency');
  const [sortOrder, setSortOrder] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const keywordsPerPage = 10;

  // Provide default values for settings
  const {
    minWordLength = 3,
    excludeCommonWords = false,
    filterOption = 'all'
  } = settings;

  const keywords = useMemo(() => {
    const words = text.toLowerCase().match(/\b(\w+)\b/g) || [];
    const wordCounts = words.reduce((acc, word) => {
      if (!excludeCommonWords || !commonWords.includes(word)) {
        acc[word] = (acc[word] || 0) + 1;
      }
      return acc;
    }, {});

    const keywordList = Object.entries(wordCounts)
      .filter(([word]) => word.length >= minWordLength)
      .map(([word, count]) => ({
        word,
        count,
        density: (count / words.length) * 100,
      }));

    return keywordList;
  }, [text, minWordLength, excludeCommonWords]);

  const sortedKeywords = useMemo(() => {
    let sorted = [...keywords];

    switch (sortBy) {
      case 'alphabetical':
        sorted.sort((a, b) => a.word.localeCompare(b.word));
        break;
      case 'frequency':
        sorted.sort((a, b) => b.count - a.count);
        break;
      case 'density':
        sorted.sort((a, b) => b.density - a.density);
        break;
    }

    if (sortOrder === 'asc') {
      sorted.reverse();
    }

    return sorted;
  }, [keywords, sortBy, sortOrder]);

  const filteredKeywords = useMemo(() => {
    switch (filterOption) {
      case 'top10':
        return sortedKeywords.slice(0, 10);
      case 'top20':
        return sortedKeywords.slice(0, 20);
      case 'frequent':
        return sortedKeywords.filter(({ count }) => count > 1);
      default:
        const startIndex = (currentPage - 1) * keywordsPerPage;
        return sortedKeywords.slice(startIndex, startIndex + keywordsPerPage);
    }
  }, [sortedKeywords, filterOption, currentPage]);

  const totalPages = Math.ceil(sortedKeywords.length / keywordsPerPage);

  const handleSort = (newSortBy) => {
    if (newSortBy === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(newSortBy);
      setSortOrder('desc');
    }
  };

  const getSortIcon = (column) => {
    if (sortBy !== column) return <FaSort />;
    return sortOrder === 'asc' 
      ? (column === 'alphabetical' ? <FaSortAlphaUp /> : <FaSortNumericUp />)
      : (column === 'alphabetical' ? <FaSortAlphaDown /> : <FaSortNumericDown />);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 border border-gray-200 dark:border-gray-700">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 space-y-2 sm:space-y-0">
        <h3 className="text-lg font-semibold">Keywords</h3>
        <div className="flex flex-wrap items-center gap-2">
          <select
            value={filterOption}
            onChange={(e) => {
              onUpdateSettings('keywords', 'filterOption', e.target.value);
              setCurrentPage(1);
            }}
            className="bg-secondary text-secondary-foreground rounded-md px-2 py-1 text-sm"
          >
            <option value="all">All</option>
            <option value="top10">Top 10</option>
            <option value="top20">Top 20</option>
            <option value="frequent">Frequent</option>
          </select>
          <Tooltip content="Exclude common words">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={excludeCommonWords}
                onChange={() => onUpdateSettings('keywords', 'excludeCommonWords', !excludeCommonWords)}
                className="form-checkbox h-4 w-4 text-primary"
              />
              <span className="ml-2 text-sm">Exclude common</span>
            </label>
          </Tooltip>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-700">
              <th className="px-2 py-1 text-left cursor-pointer" onClick={() => handleSort('alphabetical')}>
                <div className="flex items-center">
                  Keyword {getSortIcon('alphabetical')}
                </div>
              </th>
              <th className="px-2 py-1 text-right cursor-pointer" onClick={() => handleSort('frequency')}>
                <div className="flex items-center justify-end">
                  Count {getSortIcon('frequency')}
                </div>
              </th>
              <th className="px-2 py-1 text-right cursor-pointer" onClick={() => handleSort('density')}>
                <div className="flex items-center justify-end">
                  Density {getSortIcon('density')}
                </div>
              </th>
            </tr>
          </thead>
          <AnimatePresence mode="wait">
            <motion.tbody
              key={currentPage}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {filteredKeywords.map(({ word, count, density }, index) => (
                <motion.tr
                  key={word}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2, delay: index * 0.05 }}
                  className="border-b dark:border-gray-600"
                >
                  <td className="px-2 py-1 break-all">{word}</td>
                  <td className="px-2 py-1 text-right">{count}</td>
                  <td className="px-2 py-1 text-right">{density.toFixed(2)}%</td>
                </motion.tr>
              ))}
            </motion.tbody>
          </AnimatePresence>
        </table>
      </div>
      {filterOption === 'all' && totalPages > 1 && (
        <motion.div
          className="flex justify-between items-center mt-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="btn btn-secondary text-sm px-2 py-1"
          >
            <FaChevronLeft />
          </motion.button>
          <motion.span
            key={currentPage}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            className="text-sm"
          >
            {currentPage} of {totalPages}
          </motion.span>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="btn btn-secondary text-sm px-2 py-1"
          >
            <FaChevronRight />
          </motion.button>
        </motion.div>
      )}
    </div>
  );
};

export default KeywordsBox;
