import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ClearConfirmDialog = ({ isOpen, onClose, onConfirm }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
            className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-sm mx-auto"
          >
            <h2 className="text-xl font-bold mb-4">Clear Text</h2>
            <p className="mb-6">Are you sure you want to clear all text? This action cannot be undone.</p>
            <div className="flex justify-end space-x-4">
              <button onClick={onClose} className="btn btn-secondary">
                Cancel
              </button>
              <button onClick={onConfirm} className="btn btn-destructive">
                Clear
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ClearConfirmDialog;
