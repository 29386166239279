import React, { useState, useEffect } from 'react';
import WordCounter from './components/WordCounter';
import Header from './components/Header';
import Footer from './components/Footer';
import ContentSection from './components/ContentSection';

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  return (
    <div className={`min-h-screen flex flex-col ${darkMode ? 'dark' : ''}`}>
      <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <main className="flex-grow bg-gradient-to-b from-background to-background/80 text-foreground">
        <div className="container mx-auto px-4 py-8">
          <WordCounter />
          <ContentSection />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
