import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white dark:bg-gray-800 shadow-md transition-colors duration-200">
      <div className="container mx-auto px-4 py-4 flex justify-center items-center">
        <p className="text-sm text-gray-600 dark:text-gray-400">
          © {currentYear} WordCounter. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
