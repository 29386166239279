import React from 'react';

const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const CountBox = ({ wordCount, charCount }) => {
  return (
    <div className="w-full bg-blue-100 dark:bg-blue-900 p-3 rounded-lg shadow-md transition-colors duration-200">
      <div className="flex justify-start items-center text-xl sm:text-2xl md:text-3xl text-gray-800 dark:text-gray-200">
        <span className="font-bold mr-2">{formatNumber(wordCount)}</span>
        <span className="font-semibold mr-4">words</span>
        <span className="font-bold mr-2">{formatNumber(charCount)}</span>
        <span className="font-semibold">characters</span>
      </div>
    </div>
  );
};

export default CountBox;
