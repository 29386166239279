import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaKey, FaCog, FaCheckCircle } from 'react-icons/fa';

const ContentSection = () => {
  const [activeFAQ, setActiveFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setActiveFAQ(activeFAQ === index ? null : index);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8">Your All-in-One Writing Companion: Free Word Counter and More</h2>
      <h2 className="text-xl sm:text-2xl font-semibold text-center mb-12">Count Words, Analyze Text, and Improve Your Writing</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <div>
          <h3 className="text-lg font-semibold mb-4 flex items-center">
            <FaKey className="mr-2 text-primary" /> Key Features:
          </h3>
          <ul className="list-disc pl-5 space-y-2">
            <li><strong>Instant Word Count:</strong> Get an accurate word count in seconds.</li>
            <li><strong>Character Count:</strong> Track the number of characters in your text.</li>
            <li><strong>Keyword Density Analysis:</strong> Optimize your content for search engines.</li>
            <li><strong>Built-in Text Editor:</strong> Easily edit and format your text.</li>
            <li><strong>No Downloads Required:</strong> Access from any device with a browser.</li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4 flex items-center">
            <FaCog className="mr-2 text-primary" /> How it Works:
          </h3>
          <ol className="list-decimal pl-5 space-y-2">
            <li>Paste your text into the box.</li>
            <li>The word count, character count, and keyword density analysis will appear instantly.</li>
          </ol>
          <h3 className="text-lg font-semibold mt-8 mb-4 flex items-center">
            <FaCheckCircle className="mr-2 text-primary" /> Why Choose Our Word Counter Tool?
          </h3>
          <ul className="list-disc pl-5 space-y-2">
            <li><strong>Free and Online:</strong> No need to download or install anything.</li>
            <li><strong>Accurate and Reliable:</strong> Get precise word and character counts.</li>
            <li><strong>Versatile:</strong> Use it for essays, articles, blog posts, and more.</li>
            <li><strong>Advanced Features:</strong> Benefit from additional features like keyword density analysis and a built-in text editor.</li>
          </ul>
        </div>
      </div>

      <h2 className="text-2xl font-semibold mb-6">Word Counter FAQs:</h2>
      <div className="space-y-4">
        {[
          {
            question: "Can I use this word counter tool for academic papers?",
            answer: "Absolutely! Our tool is ideal for essays, research papers, and other academic writing."
          },
          {
            question: "Is there a limit to the number of words I can count?",
            answer: "There is no limit to the number of words you can count using our free word counter."
          },
          {
            question: "Is my data private?",
            answer: "Your data is kept private. We do not store or share any information you enter into our tool."
          },
          {
            question: "How does this word counter tool work?",
            answer: "Our tool uses advanced algorithms to analyze your text and provide accurate word count, character count, and keyword density information."
          },
          {
            question: "Can I use this tool for checking my blog post length?",
            answer: "Yes, you can use our word counter to ensure your blog posts meet your desired word count."
          },
          {
            question: "Is there a mobile app for your word counter?",
            answer: "While we don't have a dedicated mobile app, our tool is fully responsive and works great on mobile devices."
          }
        ].map((faq, index) => (
          <div key={index} className="border rounded-lg overflow-hidden">
            <button
              className="w-full text-left p-4 focus:outline-none bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200 flex justify-between items-center"
              onClick={() => toggleFAQ(index)}
            >
              <h3 className="font-semibold text-foreground">{faq.question}</h3>
              {activeFAQ === index ? <FaChevronUp className="text-foreground" /> : <FaChevronDown className="text-foreground" />}
            </button>
            {activeFAQ === index && (
              <div className="p-4 bg-white dark:bg-gray-900">
                <p className="text-foreground">{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentSection;
