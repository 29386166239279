import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaClipboard, FaEraser, FaShare, FaTwitter, FaFacebook, FaLinkedin, FaCopy, 
  FaWhatsapp, FaInstagram, FaUndo, FaRedo, FaDownload, FaUpload, FaSave, FaCog, 
  FaMarkdown, FaShareAlt, FaReddit, FaAlignLeft, FaAlignCenter, FaAlignRight, FaTrash, FaPencilAlt, FaInfoCircle, FaSync, FaTextHeight 
} from 'react-icons/fa';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { Document, Packer, Paragraph, BlobWriter } from 'docx';
import * as pdfjsLib from 'pdfjs-dist';
import mammoth from 'mammoth';
import StatBox from './StatBox';
import ContentSection from './ContentSection';
import SettingsPopup from './SettingsPopup';
import KeywordsBox from './KeywordsBox';
import StatsKeywordsSwitch from './StatsKeywordsSwitch';
import Tooltip from './Tooltip';
import Dropdown, { DropdownMenuItem } from './Dropdown';
import ClearConfirmDialog from './ClearConfirmDialog';
import Toast from './Toast';
import ReactMarkdown from 'react-markdown';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import StatsSettingsPopup from './StatsSettingsPopup';
import { useClickOutside } from '../hooks/useClickOutside'; // Add this import
import SharePopup from './SharePopup';
import CountBox from './CountBox';

// Set up PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const WordCounter = () => {
  const [text, setText] = useState(() => localStorage.getItem('wordCounterText') || '');
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [textHistory, setTextHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [isAutoSaveOn, setIsAutoSaveOn] = useState(false);
  const shareRef = useRef(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [buttonSettings, setButtonSettings] = useState({
    Copy: true,
    Clear: true,
    Undo: true,
    Redo: true,
    Save: true,
    Upload: true,
    AutoSave: true,
    Case: true,
    Align: true,
    Markdown: true,
    Keywords: true,
    Share: true, // This will not be removable
  });

  const [showCopyLinkToast, setShowCopyLinkToast] = useState(false);

  const [isMarkdownMode, setIsMarkdownMode] = useState(false);
  const [isWysiwygMode, setIsWysiwygMode] = useState(false);

  const [darkMode, setDarkMode] = useState(() => JSON.parse(localStorage.getItem('darkMode')) || false);

  const [showClearConfirm, setShowClearConfirm] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  const [supportedFileTypes] = useState('.txt,.pdf,.docx,.xlsx,.rtf');

  const [settings, setSettings] = useState(() => {
    const savedSettings = localStorage.getItem('wordCounterSettings');
    return savedSettings ? JSON.parse(savedSettings) : {
      buttons: {
        Copy: true,
        Clear: true,
        Undo: true,
        Redo: true,
        Save: true,
        Upload: true,
        Case: true,
        Align: true,
        Markdown: true,
        "Text Editor": true,
        Share: true,
      },
      stats: {
        words: true,
        characters: true,
        charactersNoSpaces: true,
        sentences: true,
        paragraphs: true,
        lines: true,
        uniqueWords: true,
        longestSentence: true,
        shortestSentence: true,
        avgSentenceWords: true,
        avgSentenceChars: true,
        avgWordLength: true,
        syllables: true,
        readingLevel: true,
        lexicalDensity: true,
        readingTime: true,
        speakingTime: true,
        handWritingTime: true,
        pages: true,
        wordsPublisher: true,
      },
      keywords: {
        minWordLength: 3,
        excludeCommonWords: false,
        filterOption: 'all'
      }
    };
  });

  const [showAdvancedStats, setShowAdvancedStats] = useState(false);

  const [isStatsSettingsOpen, setIsStatsSettingsOpen] = useState(false);
  const statsSettingsRef = useRef(null);

  // Use the custom hook to handle click outside
  useClickOutside(statsSettingsRef, () => {
    if (isStatsSettingsOpen) {
      setIsStatsSettingsOpen(false);
    }
  });

  useEffect(() => {
    localStorage.setItem('wordCounterSettings', JSON.stringify(settings));
  }, [settings]);

  const updateSettings = (category, key, value) => {
    setSettings(prev => ({
      ...prev,
      [category]: { ...prev[category], [key]: value },
    }));
  };

  const toggleMarkdownMode = () => {
    setIsMarkdownMode(!isMarkdownMode);
  };

  const getSentenceStats = useCallback((text) => {
    const sentences = text.split(/[.!?]+/).filter(s => s.trim().length > 0);
    const lengths = sentences.map(s => s.trim().split(/\s+/).filter(w => w.length > 0).length);
    return {
      count: sentences.length,
      lengths: lengths,
      longest: Math.max(...lengths, 0),
      shortest: Math.min(...lengths, Infinity),
      avgWords: lengths.reduce((sum, len) => sum + len, 0) / sentences.length || 0
    };
  }, []);

  const countSyllables = (word) => {
    word = word.toLowerCase();
    if (word.length <= 3) return 1;
    word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '');
    word = word.replace(/^y/, '');
    return word.match(/[aeiouy]{1,2}/g)?.length || 1;
  };

  const calculateFleschKincaidGrade = (words, sentences, syllables) => {
    return 0.39 * (words / sentences) + 11.8 * (syllables / words) - 15.59;
  };

  const calculateFleschReadingEase = (words, sentences, syllables) => {
    return 206.835 - 1.015 * (words / sentences) - 84.6 * (syllables / words);
  };

  const calculateSMOGIndex = (sentences, syllables) => {
    return 1.0430 * Math.sqrt(syllables * (30 / sentences)) + 3.1291;
  };

  const calculateColemanLiauIndex = (characters, words, sentences) => {
    const L = (characters / words) * 100;
    const S = (sentences / words) * 100;
    return 0.0588 * L - 0.296 * S - 15.8;
  };

  const isStopWord = (word) => {
    const stopWords = new Set(['a', 'an', 'and', 'are', 'as', 'at', 'be', 'by', 'for', 'from', 'has', 'he', 'in', 'is', 'it',
      'its', 'of', 'on', 'that', 'the', 'to', 'was', 'were', 'will', 'with']);
    return stopWords.has(word);
  };

  const calculateReadingLevel = (words, sentences, syllables) => {
    if (words === 0 || sentences === 0) return "N/A";
    
    const fleschKincaidGrade = 0.39 * (words / sentences) + 11.8 * (syllables / words) - 15.59;
    
    if (fleschKincaidGrade <= 5) return "Elementary School";
    if (fleschKincaidGrade <= 8) return "Middle School";
    if (fleschKincaidGrade <= 12) return "High School";
    if (fleschKincaidGrade <= 16) return "College";
    if (fleschKincaidGrade <= 18) return "Graduate School";
    return "Professional";
  };

  const calculateStats = useCallback((text) => {
    // Basic counts
    const words = text.trim().split(/\s+/).filter(word => word.length > 0);
    const wordCount = words.length;
    const charCount = text.length;
    const charCountNoSpaces = text.replace(/\s/g, '').length;
    const sentences = text.split(/[.!?]+/).filter(s => s.trim().length > 0);
    const sentenceCount = sentences.length;
    const paragraphs = text.split(/\n\s*\n/).filter(p => p.trim().length > 0);
    const paragraphCount = paragraphs.length;
    const lineCount = text.split('\n').length;

    // Advanced word analysis
    const uniqueWords = new Set(words.map(word => word.toLowerCase())).size;
    const wordFrequency = words.reduce((acc, word) => {
      acc[word.toLowerCase()] = (acc[word.toLowerCase()] || 0) + 1;
      return acc;
    }, {});
    const mostFrequentWord = wordCount > 0 ? Object.entries(wordFrequency).reduce((a, b) => b[1] > a[1] ? b : a) : ['', 0];
    const longestWord = wordCount > 0 ? words.reduce((a, b) => b.length > a.length ? b : a) : '';
    const shortestWord = wordCount > 0 ? words.reduce((a, b) => b.length < a.length ? b : a) : '';
    const avgWordLength = wordCount > 0 ? charCountNoSpaces / wordCount : 0;

    // Sentence analysis
    const sentenceLengths = sentences.map(s => s.trim().split(/\s+/).filter(w => w.length > 0).length);
    const longestSentence = sentenceLengths.length > 0 ? Math.max(...sentenceLengths) : 0;
    const shortestSentence = sentenceLengths.length > 0 ? Math.min(...sentenceLengths) : 0;
    const avgSentenceWords = sentenceCount > 0 ? wordCount / sentenceCount : 0;
    const avgSentenceChars = sentenceCount > 0 ? charCount / sentenceCount : 0;

    // Paragraph analysis
    const paragraphLengths = paragraphs.map(p => p.trim().split(/\s+/).filter(w => w.length > 0).length);
    const longestParagraph = paragraphLengths.length > 0 ? Math.max(...paragraphLengths) : 0;
    const shortestParagraph = paragraphLengths.length > 0 ? Math.min(...paragraphLengths) : 0;
    const avgParagraphWords = paragraphCount > 0 ? wordCount / paragraphCount : 0;

    // Readability metrics
    const syllableCount = words.reduce((count, word) => count + countSyllables(word), 0);
    const avgSyllablesPerWord = wordCount > 0 ? syllableCount / wordCount : 0;
    const fleschKincaidGrade = calculateFleschKincaidGrade(wordCount, sentenceCount, syllableCount);
    const fleschReadingEase = calculateFleschReadingEase(wordCount, sentenceCount, syllableCount);
    const smogIndex = calculateSMOGIndex(sentenceCount, syllableCount);
    const colemanLiauIndex = calculateColemanLiauIndex(charCount, wordCount, sentenceCount);

    // Lexical density
    const lexicalWords = words.filter(word => !isStopWord(word.toLowerCase()));
    const lexicalDensity = wordCount > 0 ? (lexicalWords.length / wordCount) * 100 : 0;

    // Time estimates (in seconds)
    const readingTime = (wordCount / 200) * 60; // 200 words per minute
    const speakingTime = (wordCount / 130) * 60; // 130 words per minute
    const handWritingTime = (wordCount / 13) * 60; // 13 words per minute

    // Page estimates
    const pageCount = Math.ceil(wordCount / 250); // Assuming 250 words per page
    const wordsPublisher = Math.ceil(wordCount / 300) * 300; // Publisher's word count (rounded up to nearest 300)

    const formatTime = (seconds) => {
      if (seconds < 1) return '< 1 sec';
      
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.round(seconds % 60);

      const parts = [];
      if (hours > 0) parts.push(`${hours} hr`);
      if (minutes > 0) parts.push(`${minutes} min`);
      if (remainingSeconds > 0) parts.push(`${remainingSeconds} sec`);

      return parts.join(', ');
    };

    const readingLevel = calculateReadingLevel(wordCount, sentenceCount, syllableCount);

    return {
      words: wordCount,
      characters: charCount,
      charactersNoSpaces: charCountNoSpaces,
      sentences: sentenceCount,
      paragraphs: paragraphCount,
      lines: lineCount,
      uniqueWords,
      mostFrequentWord: wordCount > 0 ? `${mostFrequentWord[0]} (${mostFrequentWord[1]} times)` : 'N/A',
      longestWord,
      shortestWord,
      avgWordLength: avgWordLength.toFixed(2),
      longestSentence,
      shortestSentence,
      avgSentenceWords: avgSentenceWords.toFixed(2),
      avgSentenceChars: avgSentenceChars.toFixed(2),
      longestParagraph,
      shortestParagraph,
      avgParagraphWords: avgParagraphWords.toFixed(2),
      syllables: syllableCount,
      avgSyllablesPerWord: avgSyllablesPerWord.toFixed(2),
      readingLevel,
      lexicalDensity: lexicalDensity.toFixed(2) + '%',
      readingTime: formatTime(readingTime),
      speakingTime: formatTime(speakingTime),
      handWritingTime: formatTime(handWritingTime),
      pages: pageCount,
      wordsPublisher
    };
  }, []);

  const stats = useMemo(() => calculateStats(text), [text, calculateStats]);

  const handleTextChange = (e) => {
    const newText = isWysiwygMode ? e : e.target.value;
    setText(newText);
    localStorage.setItem('wordCounterText', newText);
    
    const newHistory = textHistory.slice(0, historyIndex + 1);
    newHistory.push(newText);
    setTextHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
    
    setCanUndo(true);
    setCanRedo(false);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setToastMessage('Text copied to clipboard');
    setIsToastVisible(true);
  };

  const handleClearText = () => {
    setText('');
    setTextHistory(['']);
    setHistoryIndex(0);
    setCanUndo(false);
    setCanRedo(false);
    setShowClearConfirm(false);
    setToastMessage('Text cleared');
    setIsToastVisible(true);
  };

  const handleUndo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(prev => prev - 1);
      setText(textHistory[historyIndex - 1]);
      setCanRedo(true);
      if (historyIndex - 1 === 0) {
        setCanUndo(false);
      }
    }
  };

  const handleRedo = () => {
    if (historyIndex < textHistory.length - 1) {
      setHistoryIndex(prev => prev + 1);
      setText(textHistory[historyIndex + 1]);
      setCanUndo(true);
      if (historyIndex + 1 === textHistory.length - 1) {
        setCanRedo(false);
      }
    }
  };

  const handleSave = async (format) => {
    let blob;
    switch (format) {
      case 'txt':
        blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, 'word_counter_text.txt');
        break;
      case 'pdf':
        const pdf = new jsPDF();
        pdf.text(text, 10, 10);
        blob = pdf.output('blob');
        saveAs(blob, 'word_counter_text.pdf');
        break;
      case 'docx':
        const doc = new Document({
          sections: [{
            properties: {},
            children: [
              new Paragraph(text),
            ],
          }],
        });
        blob = await Packer.toBlob(doc);
        saveAs(blob, 'word_counter_text.docx');
        break;
      case 'xlsx':
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([[text]]);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'word_counter_text.xlsx');
        break;
      case 'rtf':
        const rtfContent = `{\\rtf1\\ansi\\deff0 ${text.replace(/\n/g, '\\par ')}}`;
        blob = new Blob([rtfContent], { type: 'application/rtf' });
        saveAs(blob, 'word_counter_text.rtf');
        break;
      default:
        console.error('Unsupported file format');
        return;
    }
    setToastMessage(`File saved as ${format}`);
    setIsToastVisible(true);
  };

  const handleShare = (platform) => {
    const shareUrl = "https://wordscounter.pro";
    const shareText = "Hey, I just found an amazing tool that lets you instantly count words, characters, and keywords. Check it out for all your writing needs—it's packed with features, easy to use, and best of all, it's free and secure!";

    switch (platform) {
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`, '_blank');
        break;
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`, '_blank');
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(shareText)}`, '_blank');
        break;
      case 'whatsapp':
        window.open(`https://wa.me/?text=${encodeURIComponent(shareText + ' ' + shareUrl)}`, '_blank');
        break;
      case 'reddit':
        window.open(`https://reddit.com/submit?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(shareText)}`, '_blank');
        break;
      case 'pinterest':
        window.open(`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(shareUrl)}&description=${encodeURIComponent(shareText)}`, '_blank');
        break;
      default:
        console.log('Unsupported platform');
    }
    setIsShareOpen(false);
  };

  const handleCopyLink = () => {
    const link = "https://wordscounter.pro";
    const shareText = "Hey, I just found an amazing tool that lets you instantly count words, characters, and keywords. Check it out for all your writing needs—it's packed with features, easy to use, and best of all, it's free and secure!";
    const fullText = `${shareText} ${link}`;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(fullText)
        .then(() => {
          setShowCopyLinkToast(true);
          setTimeout(() => setShowCopyLinkToast(false), 3000);
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          alert('Failed to copy the link. Please try again.');
        });
    } else {
      // Fallback for older browsers
      const tempInput = document.createElement('input');
      tempInput.value = fullText;
      document.body.appendChild(tempInput);
      tempInput.select();
      try {
        document.execCommand('copy');
        setShowCopyLinkToast(true);
        setTimeout(() => setShowCopyLinkToast(false), 3000);
      } catch (err) {
        console.error('Fallback: Failed to copy: ', err);
        alert('Failed to copy the link. Please try again.');
      }
      document.body.removeChild(tempInput);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setIsShareOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const supportedTypes = ['txt', 'rtf', 'pdf', 'docx', 'xlsx'];
    const fileType = file.name.split('.').pop().toLowerCase();

    if (!supportedTypes.includes(fileType)) {
      setToastMessage(`Error: Unsupported file type. Please upload a ${supportedTypes.join(', ')} file.`);
      setIsToastVisible(true);
      return;
    }

    try {
      let content = '';

      switch (fileType) {
        case 'txt':
        case 'rtf':
          content = await readTextFile(file);
          break;
        case 'pdf':
          content = await readPDFFile(file);
          break;
        case 'docx':
          content = await readDocxFile(file);
          break;
        case 'xlsx':
          content = await readXlsxFile(file);
          break;
        default:
          throw new Error('Unsupported file type');
      }

      setText(content);
      setToastMessage(`File "${file.name}" uploaded successfully`);
      setIsToastVisible(true);
    } catch (error) {
      console.error('Error reading file:', error);
      setToastMessage(`Error reading file: ${error.message}`);
      setIsToastVisible(true);
    }
  };

  const readTextFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(new Error('Error reading text file'));
      reader.readAsText(file);
    });
  };

  const readPDFFile = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    let content = '';
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      content += textContent.items.map(item => item.str).join(' ') + '\n';
    }
    return content;
  };

  const readDocxFile = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    return result.value;
  };

  const readXlsxFile = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const workbook = XLSX.read(arrayBuffer, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const content = XLSX.utils.sheet_to_txt(worksheet);
    return content;
  };

  useEffect(() => {
    const savedText = localStorage.getItem('wordCounterText');
    if (savedText) {
      setText(savedText);
      setTextHistory([savedText]);
      setHistoryIndex(0);
    }

    const savedDarkMode = localStorage.getItem('darkMode');
    if (savedDarkMode) {
      setDarkMode(JSON.parse(savedDarkMode));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const toggleButton = (buttonName) => {
    setButtonSettings(prev => ({
      ...prev,
      [buttonName]: !prev[buttonName]
    }));
  };

  const renderButton = (name, icon, onClick, className = "btn btn-primary") => {
    if (!buttonSettings[name]) return null;
    return (
      <button onClick={onClick} className={`${className} text-xs sm:text-sm hover:bg-opacity-80 transition-colors duration-200`}>
        {icon} {name}
      </button>
    );
  };

  const handleCaseChange = (caseType) => (e) => {
    e.preventDefault();
    const textarea = document.getElementById('textArea');
    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;
    const selectedText = text.substring(selectionStart, selectionEnd);
    let newText = '';

    switch (caseType) {
      case 'uppercase':
        newText = selectedText.toUpperCase();
        break;
      case 'lowercase':
        newText = selectedText.toLowerCase();
        break;
      case 'capitalize':
        newText = selectedText.replace(/\b\w/g, l => l.toUpperCase());
        break;
      case 'sentenceCase':
        newText = selectedText.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, l => l.toUpperCase());
        break;
      case 'titleCase':
        newText = selectedText.replace(/\b\w+/g, w => w[0].toUpperCase() + w.slice(1).toLowerCase());
        break;
      case 'camelCase':
        newText = selectedText.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => 
          index === 0 ? word.toLowerCase() : word.toUpperCase()
        ).replace(/\s+/g, '');
        break;
      default:
        newText = selectedText;
    }

    const updatedText = text.substring(0, selectionStart) + newText + text.substring(selectionEnd);
    setText(updatedText);
    
    // Restore the selection
    setTimeout(() => {
      textarea.setSelectionRange(selectionStart, selectionEnd);
      textarea.focus();
    }, 0);
  };

  const [isCaseOpen, setIsCaseOpen] = useState(false);

  const handleAlign = (alignment) => {
    let alignedText = '';
    const lines = text.split('\n');

    switch (alignment) {
      case 'left':
        alignedText = lines.map(line => line.trimStart()).join('\n');
        break;
      case 'center':
        const maxLength = Math.max(...lines.map(line => line.trim().length));
        alignedText = lines.map(line => {
          const trimmedLine = line.trim();
          const padding = Math.floor((maxLength - trimmedLine.length) / 2);
          return ' '.repeat(padding) + trimmedLine;
        }).join('\n');
        break;
      case 'right':
        const maxLen = Math.max(...lines.map(line => line.trim().length));
        alignedText = lines.map(line => {
          const trimmedLine = line.trim();
          const padding = maxLen - trimmedLine.length;
          return ' '.repeat(padding) + trimmedLine;
        }).join('\n');
        break;
      default:
        alignedText = text;
    }

    setText(alignedText);
  };

  const [isAlignOpen, setIsAlignOpen] = useState(false);

  const [activeTab, setActiveTab] = useState('stats');

  const formatStatLabel = (key) => {
    return key
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase())
      .replace(/Avg/, 'Average')
      .replace(/Chars/, 'Characters')
      .replace(/No Spaces/, 'No Spaces');
  };

  const getStatInfo = (key) => {
    switch (key) {
      case 'words':
        return 'Total number of words in the text.\nCount words to measure content length.';
      case 'characters':
        return 'Total number of characters, including spaces.\nUse for character limits in social media posts.';
      case 'charactersNoSpaces':
        return 'Total number of characters, excluding spaces.\nUseful for pricing in translation services.';
      case 'sentences':
        return 'Total number of sentences in the text.\nHelps assess text structure and complexity.';
      case 'paragraphs':
        return 'Total number of paragraphs in the text.\nUseful for analyzing text organization.';
      case 'lines':
        return 'Total number of lines in the text.\nHelpful for formatting and layout purposes.';
      case 'uniqueWords':
        return 'Number of distinct words used.\nIndicates vocabulary richness and diversity.';
      case 'mostFrequentWord':
        return 'The word that appears most often and its frequency.\nIdentifies key themes or overused words.';
      case 'longestWord':
        return 'The longest word in the text.\nHighlights complex or specialized vocabulary.';
      case 'shortestWord':
        return 'The shortest word in the text.\nCan indicate use of abbreviations or simple words.';
      case 'avgWordLength':
        return 'Average length of words in characters.\nIndicates text complexity and readability.';
      case 'longestSentence':
        return 'Number of words in the longest sentence.\nIdentifies potentially complex sentences.';
      case 'shortestSentence':
        return 'Number of words in the shortest sentence.\nShows variation in sentence structure.';
      case 'avgSentenceWords':
        return 'Average number of words per sentence.\nIndicates sentence complexity and readability.';
      case 'avgSentenceChars':
        return 'Average number of characters per sentence.\nAnother measure of sentence complexity.';
      case 'longestParagraph':
        return 'Number of words in the longest paragraph.\nIdentifies detailed or complex sections.';
      case 'shortestParagraph':
        return 'Number of words in the shortest paragraph.\nShows variation in paragraph structure.';
      case 'avgParagraphWords':
        return 'Average number of words per paragraph.\nIndicates paragraph complexity and structure.';
      case 'syllables':
        return 'Total number of syllables in the text.\nUsed in various readability formulas.';
      case 'avgSyllablesPerWord':
        return 'Average number of syllables per word.\nIndicates word complexity and potential difficulty.';
      case 'readingLevel':
        return 'Estimated education level required to understand the text.\nBased on the Flesch-Kincaid Grade Level formula.';
      case 'lexicalDensity':
        return 'Percentage of content words in the text.\nHigher density indicates more informational content.';
      case 'readingTime':
        return 'Estimated time to read the text.\nBased on an average reading speed of 200 words per minute.';
      case 'speakingTime':
        return 'Estimated time to speak the text.\nBased on an average speaking speed of 130 words per minute.';
      case 'handWritingTime':
        return 'Estimated time to write the text by hand.\nBased on an average writing speed of 13 words per minute.';
      case 'pages':
        return 'Estimated number of pages.\nBased on 250 words per page, standard for manuscripts.';
      case 'wordsPublisher':
        return 'Word count rounded up to nearest 300.\nUsed by some publishers for word count estimates.';
      default:
        return '';
    }
  };

  const getButtonColor = (isEnabled, isDarkMode, buttonType) => {
    if (buttonType === 'Clear' || buttonType === 'Redo') {
      return isDarkMode ? 'bg-red-600 hover:bg-red-700' : 'bg-red-500 hover:bg-red-600';
    }
    if (buttonType === 'Settings') {
      return isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600';
    }
    if (buttonType === 'Markdown' || buttonType === 'TextEditor') {
      return isEnabled
        ? (isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600')
        : (isDarkMode ? 'bg-red-600 hover:bg-red-700' : 'bg-red-500 hover:bg-red-600');
    }
    if (isEnabled) {
      return isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600';
    }
    return isDarkMode ? 'bg-gray-600 hover:bg-gray-700' : 'bg-gray-500 hover:bg-gray-600';
  };

  const buttonClass = (isEnabled, buttonType = '') => `
    btn ${getButtonColor(isEnabled, darkMode, buttonType)} text-white 
    flex flex-col items-center p-1 sm:p-2
    transition-colors duration-200
    shadow-md hover:shadow-lg
    focus:outline-none focus:ring-2 focus:ring-offset-2 
    ${darkMode ? 'focus:ring-green-400' : 'focus:ring-green-500'}
  `;

  const handleUpdateSettings = (key, value) => {
    setButtonSettings(prevSettings => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  // Update the StatBox component
  const StatBox = ({ label, value, info }) => {
    const [showInfo, setShowInfo] = useState(false);

    return (
      <div className="mb-4">
        <div className="flex items-center justify-between">
          <span className="font-semibold">{label}:</span>
          <span>{value}</span>
        </div>
        <button 
          className="text-sm text-blue-500 hover:text-blue-700 flex items-center mt-1"
          onClick={() => setShowInfo(!showInfo)}
        >
          <FaInfoCircle className="mr-1" />
          {showInfo ? 'Hide Info' : 'Show Info'}
        </button>
        {showInfo && (
          <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">{info}</p>
        )}
      </div>
    );
  };

  // Modify the stats rendering section
  const renderStats = () => {
    const visibleStats = Object.entries(stats).filter(([key]) => settings.stats[key]);
    const defaultStats = visibleStats.slice(0, 4);
    const advancedStats = visibleStats.slice(4);

    return (
      <>
        {defaultStats.map(([key, value]) => (
          <StatBox 
            key={key} 
            label={formatStatLabel(key)} 
            value={value} 
            info={getStatInfo(key)}
          />
        ))}
        {showAdvancedStats && advancedStats.map(([key, value]) => (
          <StatBox 
            key={key} 
            label={formatStatLabel(key)} 
            value={value} 
            info={getStatInfo(key)}
          />
        ))}
      </>
    );
  };

  const toggleDarkMode = () => {
    setDarkMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', JSON.stringify(newMode));
      return newMode;
    });
  };

  const handleCopyText = (e) => {
    // Prevent default button behavior
    e.preventDefault();
    
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(() => {
        setToastMessage('Text copied to clipboard');
        setIsToastVisible(true);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
        setToastMessage('Failed to copy text. Please try manually.');
        setIsToastVisible(true);
      });
    } else {
      // Fallback for browsers that don't support clipboard API
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = 'fixed';  // Make it invisible
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        setToastMessage('Text copied to clipboard');
        setIsToastVisible(true);
      } catch (err) {
        console.error('Failed to copy text: ', err);
        setToastMessage('Failed to copy text. Please try manually.');
        setIsToastVisible(true);
      }
      document.body.removeChild(textArea);
    }
  };

  const handleUploadClick = () => {
    // Implement file upload logic here
    console.log('Upload clicked');
  };

  const toggleAutoSave = () => {
    setIsAutoSaveOn(!isAutoSaveOn);
  };

  const handleChangeCase = (caseType) => {
    let newText = text;
    switch (caseType) {
      case 'upper':
        newText = text.toUpperCase();
        break;
      case 'lower':
        newText = text.toLowerCase();
        break;
      case 'title':
        newText = text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
        break;
      case 'sentence':
        newText = text.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, c => c.toUpperCase());
        break;
      default:
        break;
    }
    setText(newText);
  };

  const [isSaveOpen, setIsSaveOpen] = useState(false);

  const [alignment, setAlignment] = useState('left');

  const handleAlignmentChange = (newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <div className={`container mx-auto px-2 sm:px-4 py-4 sm:py-8 max-w-7xl ${darkMode ? 'dark' : ''}`}>
      <div className="flex flex-col space-y-4 sm:space-y-6">
        <main className="flex flex-col lg:flex-row gap-4 sm:gap-8">
          <div className="w-full lg:w-2/3 space-y-4">
            <div className="w-full">
              <CountBox wordCount={stats.words} charCount={stats.characters} />
            </div>
            <div className="flex flex-wrap justify-start gap-1 sm:gap-2 mb-2">
              {settings.buttons.Copy && (
                <Tooltip content="Copy text" side="bottom">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={buttonClass(true)}
                    onClick={handleCopyText}
                  >
                    <FaCopy className="text-sm sm:text-lg mb-0 sm:mb-1" />
                    <span className="text-[10px] sm:text-xs">Copy</span>
                  </motion.button>
                </Tooltip>
              )}
              {settings.buttons.Clear && (
                <Tooltip content="Clear text" side="bottom">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={buttonClass(true, 'Clear')}
                    onClick={() => setShowClearConfirm(true)}
                  >
                    <FaTrash className="text-sm sm:text-lg mb-0 sm:mb-1" />
                    <span className="text-[10px] sm:text-xs">Clear</span>
                  </motion.button>
                </Tooltip>
              )}
              {settings.buttons.Undo && (
                <Tooltip content="Undo" side="bottom">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={buttonClass(canUndo)}
                    onClick={handleUndo}
                    disabled={!canUndo}
                  >
                    <FaUndo className="text-sm sm:text-lg mb-0 sm:mb-1" />
                    <span className="text-[10px] sm:text-xs">Undo</span>
                  </motion.button>
                </Tooltip>
              )}
              {settings.buttons.Redo && (
                <Tooltip content="Redo" side="bottom">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={buttonClass(canRedo, 'Redo')}
                    onClick={handleRedo}
                    disabled={!canRedo}
                  >
                    <FaRedo className="text-sm sm:text-lg mb-0 sm:mb-1" />
                    <span className="text-[10px] sm:text-xs">Redo</span>
                  </motion.button>
                </Tooltip>
              )}
              {settings.buttons.Save && (
                <Tooltip content="Save as file" side="bottom">
                  <Dropdown
                    trigger={
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className={buttonClass(true)}
                      >
                        <FaSave className="text-sm sm:text-lg mb-0 sm:mb-1" />
                        <span className="text-[10px] sm:text-xs">Save</span>
                      </motion.button>
                    }
                  >
                    <DropdownMenuItem onClick={() => handleSave('txt')}>Save as TXT</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleSave('pdf')}>Save as PDF</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleSave('docx')}>Save as DOCX</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleSave('xlsx')}>Save as XLSX</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleSave('rtf')}>Save as RTF</DropdownMenuItem>
                  </Dropdown>
                </Tooltip>
              )}
              {settings.buttons.Upload && (
                <Tooltip content="Upload file" side="bottom">
                  <motion.label
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`${buttonClass(true)} cursor-pointer`}
                  >
                    <FaUpload className="text-sm sm:text-lg mb-0 sm:mb-1" />
                    <span className="text-[10px] sm:text-xs">Upload</span>
                    <input
                      type="file"
                      accept={supportedFileTypes}
                      onChange={handleUpload}
                      className="hidden"
                    />
                  </motion.label>
                </Tooltip>
              )}
              {settings.buttons.AutoSave && (
                <Tooltip content={isAutoSaveOn ? "Turn off auto-save" : "Turn on auto-save"} side="bottom">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={buttonClass(isAutoSaveOn)}
                    onClick={toggleAutoSave}
                  >
                    <FaSync className="text-sm sm:text-lg mb-0 sm:mb-1" />
                    <span className="text-[10px] sm:text-xs">Auto-Save</span>
                  </motion.button>
                </Tooltip>
              )}
              {settings.buttons.Case && (
                <Tooltip content="Change text case" side="bottom">
                  <Dropdown
                    trigger={
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className={buttonClass(true)}
                      >
                        <FaTextHeight className="text-sm sm:text-lg mb-0 sm:mb-1" />
                        <span className="text-[10px] sm:text-xs">Case</span>
                      </motion.button>
                    }
                    menuClass="w-24 sm:w-32" // Narrower width for case dropdown
                  >
                    <DropdownMenuItem onClick={() => handleChangeCase('upper')}>UPPERCASE</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleChangeCase('lower')}>lowercase</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleChangeCase('title')}>Title Case</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleChangeCase('sentence')}>Sentence case</DropdownMenuItem>
                  </Dropdown>
                </Tooltip>
              )}
              {settings.buttons.Align && (
                <Tooltip content="Text alignment" side="bottom">
                  <Dropdown
                    trigger={
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className={buttonClass(true)}
                      >
                        <FaAlignLeft className="text-sm sm:text-lg mb-0 sm:mb-1" />
                        <span className="text-[10px] sm:text-xs">Align</span>
                      </motion.button>
                    }
                    menuClass="w-20 sm:w-28" // Even narrower width for align dropdown
                  >
                    <DropdownMenuItem onClick={() => handleAlignmentChange('left')}>Left</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleAlignmentChange('center')}>Center</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleAlignmentChange('right')}>Right</DropdownMenuItem>
                  </Dropdown>
                </Tooltip>
              )}
              {settings.buttons.Markdown && (
                <Tooltip content={isMarkdownMode ? "Disable Markdown" : "Enable Markdown"} side="bottom">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={buttonClass(isMarkdownMode, 'Markdown')}
                    onClick={() => {
                      setIsMarkdownMode(!isMarkdownMode);
                      setIsWysiwygMode(false);
                    }}
                  >
                    <FaMarkdown className="text-sm sm:text-lg mb-0 sm:mb-1" />
                    <span className="text-[10px] sm:text-xs">Markdown</span>
                  </motion.button>
                </Tooltip>
              )}
              {settings.buttons["Text Editor"] && (
                <Tooltip content={isWysiwygMode ? "Disable Text Editor" : "Enable Text Editor"} side="bottom">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={buttonClass(isWysiwygMode, 'TextEditor')}
                    onClick={() => {
                      setIsWysiwygMode(!isWysiwygMode);
                      setIsMarkdownMode(false);
                    }}
                  >
                    <FaPencilAlt className="text-sm sm:text-lg mb-0 sm:mb-1" />
                    <span className="text-[10px] sm:text-xs">Editor</span>
                  </motion.button>
                </Tooltip>
              )}
              <Tooltip content="Share this tool" side="bottom">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={buttonClass(true)}
                  onClick={() => setIsShareOpen(true)}
                >
                  <FaShareAlt className="text-sm sm:text-lg mb-0 sm:mb-1" />
                  <span className="text-[10px] sm:text-xs">Share</span>
                </motion.button>
              </Tooltip>
              <Tooltip content="Settings" side="bottom">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={buttonClass(true, 'Settings')}
                  onClick={() => setIsSettingsOpen(true)}
                >
                  <FaCog className="text-sm sm:text-lg mb-0 sm:mb-1" />
                  <span className="text-[10px] sm:text-xs">Settings</span>
                </motion.button>
              </Tooltip>
            </div>
            <div className="w-full">
              {isMarkdownMode ? (
                <div className="flex flex-col space-y-4">
                  <textarea
                    id="textArea"
                    className="w-full h-48 sm:h-64 md:h-80 lg:h-96 p-2 sm:p-4 rounded-md border border-input bg-background text-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 resize-y transition-shadow duration-200 ease-in-out shadow-sm hover:shadow-md text-sm sm:text-base font-mono"
                    value={text}
                    onChange={handleTextChange}
                    placeholder="Type or paste your Markdown here..."
                    style={{ textAlign: alignment }}
                  />
                  <div className="prose prose-sm sm:prose lg:prose-lg dark:prose-invert max-w-none">
                    <ReactMarkdown>{text}</ReactMarkdown>
                  </div>
                </div>
              ) : isWysiwygMode ? (
                <div className="h-48 sm:h-64 md:h-80 lg:h-96 overflow-y-auto">
                  <ReactQuill
                    value={text}
                    onChange={(value) => setText(value)}
                    modules={{
                      toolbar: [
                        [{ 'header': [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                        ['link', 'image'],
                        ['clean']
                      ],
                    }}
                    formats={[
                      'header',
                      'bold', 'italic', 'underline', 'strike', 'blockquote',
                      'list', 'bullet', 'indent',
                      'link', 'image'
                    ]}
                  />
                </div>
              ) : (
                <textarea
                  id="textArea"
                  className="w-full h-48 sm:h-64 md:h-80 lg:h-96 p-2 sm:p-4 rounded-md border border-input bg-background text-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 resize-y transition-shadow duration-200 ease-in-out shadow-sm hover:shadow-md text-sm sm:text-base"
                  value={text}
                  onChange={handleTextChange}
                  placeholder="Type or paste your text here..."
                  style={{ textAlign: alignment }}
                />
              )}
            </div>
          </div>
          <div className="w-full lg:w-1/3 space-y-4">
            <StatsKeywordsSwitch
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <AnimatePresence mode="wait">
              {activeTab === "stats" ? (
                <motion.div
                  key="stats"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="bg-card dark:bg-accent/20 rounded-lg border shadow-sm p-4"
                >
                  {renderStats()}
                  <div className="flex justify-center mt-4 space-x-2">
                    <button
                      className="btn btn-secondary"
                      onClick={() => setShowAdvancedStats(!showAdvancedStats)}
                    >
                      {showAdvancedStats
                        ? "Hide Advanced Stats"
                        : "Show Advanced Stats"}
                    </button>
                    <Tooltip content="Stats Settings">
                      <button
                        className="btn btn-icon btn-secondary"
                        onClick={() => setIsStatsSettingsOpen(true)}
                      >
                        <FaCog />
                      </button>
                    </Tooltip>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  key="keywords"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <KeywordsBox
                    text={text}
                    settings={settings.keywords}
                    onUpdateSettings={updateSettings}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </main>
      </div>
      <SettingsPopup
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        settings={settings}
        onUpdateSettings={updateSettings}
      />
      <ClearConfirmDialog
        isOpen={showClearConfirm}
        onClose={() => setShowClearConfirm(false)}
        onConfirm={handleClearText}
      />
      <StatsSettingsPopup
        isOpen={isStatsSettingsOpen}
        onClose={() => setIsStatsSettingsOpen(false)}
        settings={settings}
        onUpdateSettings={handleUpdateSettings}
        ref={statsSettingsRef}
      />
      <SharePopup
        isOpen={isShareOpen}
        onClose={() => setIsShareOpen(false)}
        onShare={handleShare}
        onCopyLink={handleCopyLink}
      />

      {showCopyLinkToast && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded-md shadow-md z-[70]">
          Link copied to clipboard!
        </div>
      )}
    </div>
  );
};

export default WordCounter;
