import React, { forwardRef, useState } from 'react';
import { FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const StatsSettingsPopup = forwardRef(({ isOpen, onClose, settings, onUpdateSettings }, ref) => {
  const [expandedStat, setExpandedStat] = useState(null);

  if (!isOpen) return null;

  const toggleExpand = (key) => {
    setExpandedStat(expandedStat === key ? null : key);
  };

  const renderSettings = () => (
    <div className="space-y-2">
      {Object.entries(settings.stats).map(([key, value]) => (
        <div key={key} className="border-b border-gray-200 dark:border-gray-700 pb-2">
          <div 
            className="flex items-center justify-between cursor-pointer"
            onClick={() => toggleExpand(key)}
          >
            <label htmlFor={key} className="flex items-center cursor-pointer text-sm">
              <input
                type="checkbox"
                id={key}
                checked={value}
                onChange={() => onUpdateSettings('stats', key, !value)}
                className="form-checkbox h-4 w-4 text-blue-600 rounded"
                onClick={(e) => e.stopPropagation()}
              />
              <span className="ml-2 text-gray-700 dark:text-gray-300">{formatStatLabel(key)}</span>
            </label>
            {expandedStat === key ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          <AnimatePresence>
            {expandedStat === key && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="mt-2 text-sm text-gray-600 dark:text-gray-400 overflow-hidden"
              >
                {getStatInstruction(key)}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );

  const formatStatLabel = (key) => {
    return key
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase());
  };

  const getStatInstruction = (key) => {
    const instructions = {
      words: "Counts the total number of words in the text.",
      characters: "Counts the total number of characters, including spaces.",
      charactersNoSpaces: "Counts the total number of characters, excluding spaces.",
      sentences: "Counts the number of sentences based on punctuation.",
      paragraphs: "Counts the number of paragraphs based on line breaks.",
      lines: "Counts the number of lines in the text.",
      uniqueWords: "Counts the number of unique words used in the text.",
      longestSentence: "Shows the word count of the longest sentence.",
      shortestSentence: "Shows the word count of the shortest sentence.",
      avgSentenceWords: "Calculates the average number of words per sentence.",
      avgSentenceChars: "Calculates the average number of characters per sentence.",
      avgWordLength: "Calculates the average length of words in the text.",
      syllables: "Estimates the total number of syllables in the text.",
      readingLevel: "Estimates the reading level of the text using the Flesch-Kincaid Grade Level.",
      lexicalDensity: "Calculates the ratio of unique words to total words.",
      readingTime: "Estimates the time it takes to read the text at an average reading speed.",
      speakingTime: "Estimates the time it takes to speak the text aloud.",
      handWritingTime: "Estimates the time it would take to write the text by hand.",
      pages: "Estimates the number of pages based on average word count per page.",
      wordsPublisher: "Estimates the word count using publisher standards (250 words per page).",
    };
    return instructions[key] || "No instruction available for this stat.";
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4"
    >
      <motion.div
        ref={ref}
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.9 }}
        className="bg-white dark:bg-gray-800 rounded-lg p-4 w-full max-w-sm sm:max-w-md md:max-w-lg overflow-hidden"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Stats Settings</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
            <FaTimes />
          </button>
        </div>
        <div className="max-h-[60vh] overflow-y-auto pr-2">
          {renderSettings()}
        </div>
      </motion.div>
    </motion.div>
  );
});

export default StatsSettingsPopup;
