// src/components/SharePopup.js
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes, FaTwitter, FaFacebook, FaLinkedin, FaWhatsapp, FaInstagram, FaReddit, FaPinterest, FaLink } from 'react-icons/fa';

const SharePopup = ({ isOpen, onClose, onShare, onCopyLink }) => {
  const shareOptions = [
    { name: 'Twitter', icon: FaTwitter, platform: 'twitter' },
    { name: 'Facebook', icon: FaFacebook, platform: 'facebook' },
    { name: 'LinkedIn', icon: FaLinkedin, platform: 'linkedin' },
    { name: 'WhatsApp', icon: FaWhatsapp, platform: 'whatsapp' },
    { name: 'Reddit', icon: FaReddit, platform: 'reddit' },
    { name: 'Pinterest', icon: FaPinterest, platform: 'pinterest' },
    { name: 'Copy Link', icon: FaLink, platform: 'copylink' },
  ];

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60] p-4"
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
            className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md"
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Share</h2>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                <FaTimes />
              </button>
            </div>
            <div className="grid grid-cols-3 sm:grid-cols-4 gap-4">
              {shareOptions.map((option) => (
                <button
                  key={option.name}
                  onClick={() => {
                    if (option.alert) {
                      alert('To share on Instagram, please copy the link and share it in your Instagram app.');
                    } else if (option.platform === 'copylink') {
                      onCopyLink();
                    } else {
                      onShare(option.platform);
                    }
                    if (option.platform !== 'copylink') onClose();
                  }}
                  className="flex flex-col items-center justify-center p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                >
                  <option.icon className="text-2xl mb-1" />
                  <span className="text-xs text-center">{option.name}</span>
                </button>
              ))}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SharePopup;
